import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout pageInfo={{ pageName: "404", pageTitle: "404 - Not Found" }}>
    <Seo title="404: Not Found" />


    <Container>
      <section className="general-page" id="contact">
        <Container>
          <Row>
            <Col>
              <h1>Niet gevonden</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default NotFoundPage
